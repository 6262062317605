@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;
}

@media (--viewportMedium) {
  .root {
    margin-top: 48px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.status {
  margin-top: 20px;
}

.button {
  composes: buttonDefault from global;
  text-decoration: none;
}

.redButton {
  composes: button;
  background-color: var(--colorRedButton);

  &:hover,
  &:focus {
    background-color: var(--colorRedButtonDark);
  }
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.modal {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
  margin-top: 12px;
}